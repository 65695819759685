import classnames from 'classnames'
import { FC, CSSProperties } from 'react'

import styles from './Grid.module.scss'

interface Grid extends CSSProperties {
  className?: string
}

const Grid: FC<Grid> = ({ className, children, ...props }) => (
  <div className={classnames(className, styles.base)} style={{ ...props }}>
    {children}
  </div>
)

export default Grid
