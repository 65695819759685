import { Variables } from 'hooks/useVariables'
import { useState, useEffect, ComponentType, FC, useCallback } from 'react'
import { InputPassword, notifyError, notify } from '~shared'
import { Grid, Button } from '~ui'
import { useRouter } from 'next/router'
import useUser from '../../hooks/useUser'
import { HOME } from '../../routes/paths'

const LOCAL_STORAGE_KEY = 'local-storage-key'

const parsePrivateLocalStorageKey = (key: string): string | null => {
  try {
    return sessionStorage.getItem(key)
  } catch (e) {
    return null
  }
}

type WithFirewallProps = {
  variables: Variables
  isPrivate: boolean
}

function withFirewall<P extends object>(Component: ComponentType<P>): FC<P & WithFirewallProps> {
  return ({ variables, isPrivate = false, ...props }: WithFirewallProps) => {
    const [user, { loading }] = useUser()
    const { replace } = useRouter()
    const [isInit, setIsInit] = useState<boolean>(true)

    const [privatePagePass, setPrivatePagePass] = useState<null | string>(
      parsePrivateLocalStorageKey(LOCAL_STORAGE_KEY)
    )

    const handleRedirect = useCallback(async () => {
      if (loading) return
      if (!isPrivate) {
        setIsInit(false)
        return
      }
      if (!user) {
        await replace(HOME)
        setIsInit(false)
        return
      }
      if (user.type !== 'ADMIN') {
        await replace(HOME)
        setIsInit(false)
      } else {
        setIsInit(false)
      }
    }, [loading, replace, user, isPrivate])

    useEffect(() => {
      handleRedirect()
    }, [handleRedirect])

    useEffect(() => {
      if (isInit) return
      if (isPrivate && privatePagePass === variables.PRIVATE_PAGE_PASS) {
        notify("You're viewing the private page")
      }
    }, [isPrivate, privatePagePass, isInit])

    const setCurrentPassword = (e) => {
      e.preventDefault()
      const { value } = e.currentTarget.password
      setPrivatePagePass(value)

      if (value === variables.PRIVATE_PAGE_PASS) {
        try {
          sessionStorage.setItem(LOCAL_STORAGE_KEY, value)
        } catch {
          notifyError("The password wasn't saved locally. Try to clear your session storage")
        }
      }
    }

    if (isInit) return null

    if (isPrivate && variables && privatePagePass !== variables.PRIVATE_PAGE_PASS) {
      return (
        <form onSubmit={setCurrentPassword}>
          <Grid gap={10} width={400} margin="40vh auto">
            <InputPassword />
            <Button type="submit">Show private page</Button>
          </Grid>
        </form>
      )
    }

    return <Component {...(props as P)} />
  }
}

export default withFirewall
